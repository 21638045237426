import React from 'react';
import { Badge } from '../ui/Badge';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../ui/Card";

const ChatSidebar = ({ conversations, onConversationClick, searchTerm, setSearchTerm }) => {
  console.log("conversations sidebar ", conversations)
  return (
    <div className="px-4">

      {conversations && conversations.map((conversation) => (
        <Card
          key={conversation.id}
          className="h-15 mb-2 cursor-pointer"
          onClick={() => onConversationClick(conversation)}
        >

            {/* add in summary when available */}
            <div className='m-4 space-y-2'>
            <p className="text-sm mt-4"><b>Patient: </b>{conversation.name}</p>
            <p className="text-sm"><b>Status:</b> 
            <Badge variant="secondary">
            {!conversation.hasOwnProperty('stage') || conversation.stage !== 'done' ? "Ongoing" : "Complete"}
            </Badge></p>
            <p className="text-sm"></p>
            </div>

        </Card>
      ))}
    </div>
  );
};

export default ChatSidebar;