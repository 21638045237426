const BASE_URL = process.env.REACT_APP_API_URL || 'https://dentalexpressserver.azurewebsites.net';
//const BASE_URL = 'http://localhost:3001'

const getConversationsByPractice = async (practiceIdOrIds, page = 1, pageSize = 10, searchTerm = '', useInternalContainer = false) => {
    try {
      let endpoint;
      
      if (Array.isArray(practiceIdOrIds)) {
        endpoint = `${BASE_URL}/api/conversations?practiceIds=${practiceIdOrIds.join(',')}&page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&useInternalContainer=${useInternalContainer}`;
      } else {
        endpoint = `${BASE_URL}/api/conversations?practiceId=${practiceIdOrIds}&page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&useInternalContainer=${useInternalContainer}`;
      }
      
      console.log("Conversations URL: ", endpoint);

        // Making the GET request using fetch
        const response = await fetch(endpoint);

        // Checking if the response is successful
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Parsing the response body as JSON
        const data = await response.json();

        console.log("Conversations data", data);

        // Optionally, reformat or process the conversations data if needed
        const formattedData = data.conversations.map(conversation => {
          const messages = conversation.message || [];
          const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
          return {
              ...conversation,
              lastMessageText: lastMessage ? lastMessage.message : '',
              lastMessageSender: lastMessage ? lastMessage.sender : ''
          };
      });

        console.log("Formatted Conversations Data", formattedData);

        return formattedData;
    } catch (error) {
        console.error('Error fetching conversations:', error);
        throw error;
    }
};


export {getConversationsByPractice}