import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Badge } from '../ui/Badge';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import ChatInterface from './ChatInterface';
import ChatSidebar from './ChatSidebar';
import {Car, Send, Upload} from 'lucide-react';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../ui/Card"
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { toggleAI } from '../services/userInfoService';
import { getConversationMessages, getImage } from '../services/conversationService';
import { useNavigate, useLocation } from 'react-router-dom'; 
import { sendMessage } from '../services/patientsService'; 
import  ConversationContainer from '../ui/MessageDisplay'
import { Switch } from "../ui/Switch"
import { Label } from "../ui/Label"

const MAX_FILE_SIZE = 700 * 1024; // 700KB in bytes
  
const Conversation = () => {

        const location = useLocation();
        const { patientName } = location.state;
        const {phoneNumber} = useParams();
        // Define the state for messages
        const [messages, setMessages] = useState([]);
        // const [practiceId] = useState('');
        const navigate = useNavigate();
        const [newMessage, setNewMessage] = useState('');
        const [practiceId, setPracticeId] = useState(localStorage.getItem('practiceId') || '');
        const [isAIEnabled, setIsAIEnabled] = useState(false);
        const [selectedFiles, setSelectedFiles] = useState([]);
        const fileInputRef = useRef(null);

        useEffect(() => {
            
            let practiceId = localStorage.getItem('practiceId');
            if (!practiceId) {
                console.log('No practiceId found in local storage');
                navigate('/');
                return;
            }

            console.log("patientName ", patientName)
            console.log("phoneNumber", phoneNumber)
            
            // Prevent scrolling on the whole page
            document.documentElement.style.overflow = 'hidden';
            document.body.style.overflow = 'hidden';

            if (true) {
                window.pylon = {
                    chat_settings: {
                        app_id: "98e8ee10-f8ab-4d78-837b-bead4ef90b72",
                        email: "user.email",
                        name: "user.fullName",
                        
                    },
                };
            }

            const fetchData = async () => {
                console.log("messages ", messages)
                const [messages2, bAI] = await getConversationMessages(practiceId, phoneNumber);
                console.log("messages2 ", messages2)
                console.log("bAI", bAI)
                setMessages(messages2);
                setIsAIEnabled(bAI);
            }

            let intervalId;

            if (phoneNumber) {
                fetchData();
    
                intervalId = setInterval(fetchData, 1000);
            }

            // Cleanup function to re-enable scrolling if needed
            return () => {

                if (intervalId) {
                    clearInterval(intervalId);
                }

                document.documentElement.style.overflow = '';
                document.body.style.overflow = '';
            };
        }, [phoneNumber, navigate, messages, patientName]);
      

        const handleSendMessage = async (e) => {
          e.preventDefault();
          if (newMessage.trim() !== '' || selectedFiles.length > 0) {
            await sendMessage(newMessage, practiceId, phoneNumber, false, selectedFiles);
            setNewMessage('');
            setSelectedFiles([]);
            if (fileInputRef.current) {
              fileInputRef.current.value = ''; // Reset the file input
            }
            console.log("selectedFiles ", selectedFiles)
          }
        };
        

          const handleToggleAI = async () => {
            const newAIState = !isAIEnabled;
            setIsAIEnabled(newAIState);
            await toggleAI(phoneNumber, practiceId);
        };

        const handleFileChange = (e) => {
          const files = Array.from(e.target.files);
          const validFiles = files.filter(file => {
            if (file.size > MAX_FILE_SIZE) {
              alert(`${file.name} is larger than 700KB and will not be sent.`);
              return false;
            }
            return true;
          });
          setSelectedFiles(validFiles);
        };



  return (
    <div className="grid min-h-screen w-full md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
        <div className="hidden border-r bg-muted/40 md:block">
            <Sidebar practiceId={practiceId}/>
        </div>
        <div className="flex flex-col min-h-screen overflow-hidden"> 
                <MainContent title={patientName} subtitle={phoneNumber}>
                        <div className="h-full w-full"> 
                        <Card className="flex h-3/4 flex-col mb-5">
                        <CardHeader className="flex flex-row items-center justify-between">
                            <div>
                                <CardTitle>{patientName}</CardTitle>
                                <CardDescription>{phoneNumber}</CardDescription>
                            </div>
                            <div className="flex items-center space-x-2">
                                <Switch
                                    id="ai-mode"
                                    checked={isAIEnabled}
                                    onCheckedChange={handleToggleAI}
                                />
                                <Label htmlFor="ai-mode">AI Enabled</Label>
                            </div>
                        </CardHeader>
                        <CardContent className='flex flex-col flex-grow pt-5 overflow-hidden'>
                            {messages  ? (
                                <ConversationContainer messages={messages} />
                              )
                             : (
                              <div>No messages</div>
                            )}
                          </CardContent>
                          <CardFooter className="h-1/4 align-center">
                            <form className="flex w-full items-center space-x-2" onSubmit={handleSendMessage}>
                              <Input
                                id="message"
                                placeholder="Type your message..."
                                className="flex-1"
                                autoComplete="off"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                              />
                              <input
                                type="file"
                                accept="image/*"
                                multiple
                                onChange={handleFileChange}
                                className="hidden"
                                ref={fileInputRef}
                              />
                              <Button
                                  type="button"
                                  variant="outline"
                                  size="icon"
                                  onClick={() => fileInputRef.current?.click()}
                              >
                                  <Upload className="h-4 w-4" />
                                  <span className="sr-only">Choose files</span>
                              </Button>
                              <Button type="submit" size="icon">
                                <Send className="h-4 w-4" />
                                <span className="sr-only">Send</span>
                              </Button>
                            </form>
                            {selectedFiles.length > 0 && (
                              <div>
                                {selectedFiles.map((file, index) => (
                                  <div key={index}>{file.name}</div>
                                ))}
                              </div>
                            )}
                          </CardFooter>

                        </Card>
                        </div>
                </MainContent>
        </div>
    </div>
  );
};

export default Conversation;