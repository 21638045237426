// LoginPage.js

import React, { useState } from 'react';
import './LoginPage.css'; // Make sure to create a corresponding CSS file for styles
import { useNavigate } from 'react-router-dom'; 
import newtonLogo from '../assets/newton-logo.png'; // Update this line with the new logo file path
import { signIn } from '../services/supabaseClient';
import { getUserDetails } from '../services/userInfoService';
import AuthLayout from '../components/AuthLayout';
import { runDemo } from '../services/RunDemoService';
import { GetPracticebAdmin } from '../services/ActivityService'


const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate hook
    const demoIds = ["VarinosDemo", "ItaniDemo", "WaitlistDemo", "Jemin", "Sams Practice", "starBrite", "Southern Op", "MissionFremont", "IAS", "NaturalSmiles", "wynn"]

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');
        console.log("ignore", error, loading)
        let practiceId = ""

        try {
            const { user, session, error } = await signIn(email, password);
            if (error) {
                console.error('Error logging in:', error);
                if (error.message === 'Email not confirmed') {
                    setError('Please confirm your email before logging in.');
                } else {
                    setError(error.message);
                }
                setLoading(false);
            } else {
            // If sign-in is successful, store the JWT token in local storage
            if (session && session.access_token) {
                localStorage.setItem('jwtToken', session.access_token);
                // Fetch user details here
                const userId = email; // Adjust this line based on how you get the user ID
                console.log("email", email)
                getUserDetails(userId)
                    .then(async details => {
                        console.log('User Details:', details);
                        practiceId = details.practiceId; // Adjust this according to how you receive practiceId
                        localStorage.setItem('practiceId', practiceId);

                        // lets get the practice details now 
                        let bAdmin = false;
                        try {
                            bAdmin = await GetPracticebAdmin(practiceId);
                            console.log('Practice Admin Details:', bAdmin);
                            // Handle bAdmin data as needed
                        } catch (adminError) {
                            console.error('Error fetching practice admin details:', adminError);
                            // Handle error (e.g., set error state, show notification)
                        }

                        if(practiceId === "Smiles Dental Admin" || bAdmin)
                        {
                            localStorage.setItem('practiceId', 'SmilesDental1');
                            localStorage.setItem('practiceIdAdmin', practiceId);
                        }
                        else
                        {
                            localStorage.setItem('practiceIdAdmin', '');
                        }
                        localStorage.setItem('email', email);
                        if(["eastlink", "investorDemo", "investorJeff", "investor10 Dental", "investor11 Dental", "investor12 Dental", "investor13 Dental", "investor14 Dental", "voiceTester"].includes(practiceId))
                        {
                            runDemo("Sam", "+16178349372", practiceId, 4);
                        }
                        // Here you can store these details as needed

                        // Reset loading state and navigate to the desired page
                        setLoading(false);
                        console.log('User signed in', user, session);
                        console.log("Practice ID:", practiceId)
                        console.log("demoIds", demoIds)
                        if(true)
                        {
                            navigate('/home');
                        }
                        else
                        {
                            navigate('/schedule');
                        }                        
                    })
                    .catch(error => {
                        console.error('Error fetching user details:', error);
                        // Handle errors related to fetching user details
                    });

            } else {
                console.error('Session is undefined, unable to retrieve JWT token');
                // Optional: Set an error message about the missing session/token
                setError('Login successful, but unable to retrieve session information.');
            }
            }
        } catch (error) {
            // Handle any unexpected errors during the sign-in process
            console.error('Error logging in:', error);
                if (error.message === 'Email not confirmed') {
                    setError('Please confirm your email before logging in.');
                } else {
                    setError(error.message);
                }
            setLoading(false);
        }
    };




  return (
    <div style={{ backgroundColor: '#F9F8F6', minHeight: '100vh' }}>
      <AuthLayout>
        <img src={newtonLogo} alt="Newton Logo" className="company-logo" />
        <div className="login-container">
            <h2>Log In</h2>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div className="input-group">
                <label htmlFor="username">Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                </div>
                <div className="input-group">
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className="forgot-password"> <a href="/forgot-password"> Forgot password? </a> </div>
                </div>
                <div className="submit-group">
                <button type="submit">Log In</button>
                </div>
            </form>
            <p>Don't have an account? <a href="/register">Register here</a></p>
        </div>
      </AuthLayout>
    </div>
  );
};

export default LoginPage;




