import React, { useState } from 'react';
import {
    DialogTS,
    DialogTriggerTS,
    DialogTSContent,
    DialogTSHeader,
    DialogTSTitle,
} from "../ui/DialogTS";
import { PhoneOutgoing } from 'lucide-react';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '../ui/Table';
import { Badge } from '../ui/Badge'; // Add this import
//import Engagement from './Engagement.js';

const Settings = () => {
    const [activeItem, setActiveItem] = useState('Engagement');

    const menuItems = [
        { name: 'Engagement', icon: 'PhoneOutgoing', content: 'Engagement' },
    ];

    return (
        <DialogTSContent className="w-[1500px] h-[80vh] max-h-[700px] translate-x-[-50%] translate-y-[-50%] p-0 flex flex-col overflow-hidden">
            <DialogTSHeader className="flex-shrink-0 p-6 pb-2">
                <DialogTSTitle className="text-2xl font-semibold">
                    Settings
                </DialogTSTitle>
            </DialogTSHeader>
            <div className="flex flex-1 overflow-hidden p-6 pt-2">
                <div className="w-1/4 pr-4 flex flex-col border-r overflow-hidden">
                    <nav className="flex-1 overflow-y-auto pr-2">
                        {menuItems.map((item) => {
                            return (
                                <button
                                    key={item.name}
                                    onClick={() => setActiveItem(item.name)}
                                    className={`flex items-center gap-3 rounded-lg px-3 py-2 text-sm font-medium transition-all hover:bg-muted hover:text-primary w-full mb-2 ${
                                        activeItem === item.name ? 'bg-muted text-primary' : 'text-muted-foreground'
                                    }`}
                                >
                                  <PhoneOutgoing className="w-4 h-4" />
                                    {item.name}
                                </button>
                            );
                        })}
                    </nav>
                </div>
                <div className="flex-1 pl-4 overflow-hidden flex flex-col">
                    <h3 className="text-lg font-semibold mb-4">{activeItem}</h3>
                    <div className="flex-1 overflow-y-auto">
                        <Table className="w-full">
                            <TableHeader>
                                <TableRow>
                                    <TableHead className="w-1/4">Engagement Scenario</TableHead>
                                    <TableHead className="w-1/4">Patient Status</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                            <DialogTS>
                            <DialogTriggerTS>
                                <TableRow>
                                    
                                    <TableCell>New patient books an appointment</TableCell>
                                    
       
                                    <TableCell>
                                        <Badge variant="secondary">New Patient</Badge>
                                    </TableCell>
                                </TableRow>
                                </DialogTriggerTS>
                                {/* <Engagement/> */}
                                    </DialogTS>
                                <TableRow>
                                    <TableCell>Follow-up Appointment</TableCell>
           
                                    <TableCell>
                                        <Badge variant="outline">Unconfirmed</Badge>
                                    </TableCell>
                                </TableRow>
                                {/* Add more rows as needed */}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </DialogTSContent>
    );
};

export default Settings;