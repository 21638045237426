import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "../ui/Table"
import { Badge } from '../ui/Badge';
import { getBugList } from '../services/ActivityService';

const BugList = () => {
  const [bugs, setBugs] = useState([]);

  useEffect(() => {
    const fetchBugs = async () => {
      try {
        const bugList = await getBugList();
        setBugs(bugList);
      } catch (error) {
        console.error('Error fetching bug list:', error);
      }
    };

    fetchBugs();
  }, []);

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Description</TableHead>
          <TableHead>Status</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {bugs.map((bug, index) => (
          <TableRow key={index}>
            <TableCell>{bug.description}</TableCell>
            <TableCell>
              <Badge variant={bug.solved ? "success" : "destructive"}>
                {bug.solved ? "Solved" : "Unsolved"}
              </Badge>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default BugList;