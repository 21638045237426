const BASE_URL = process.env.REACT_APP_API_URL || 'https://dentalexpressserver.azurewebsites.net';
//const BASE_URL = 'http://localhost:3001'


function fetchFromAPI(endpoint) {
    return fetch(`${BASE_URL}${endpoint}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        });
}

async function getConversationMessages(practiceId, phoneNumber) {
    try {
        // Encode the phoneNumber to ensure special characters are correctly interpreted
        const encodedPhoneNumber = encodeURIComponent(phoneNumber);

        // Construct the endpoint URL
        var endpoint = `/api/practices/${practiceId}/conversations?phoneNumber=${encodedPhoneNumber}`;
        const conversationMessages = await fetchFromAPI(endpoint);

        console.log("conversationMessages23", conversationMessages)

        if (!conversationMessages) {
            throw new Error('No conversation messages found');
        }

        const bAI = conversationMessages[0].AI ?? true;

        console.log("conversationMessages[0] ", conversationMessages[0])

        return [conversationMessages[0].message, bAI];
    } catch (error) {
        console.error('Error fetching conversation messages:', error);
        throw error;
    }
}

const getImage = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error fetching image:', error);
      return null;
    }
  };


export { getConversationMessages, getImage };
