// dataHelpers.js
const BASE_URL = process.env.REACT_APP_API_URL || 'https://dentalexpressserver.azurewebsites.net';
//const BASE_URL = 'http://localhost:3001'; // Update this to your server's URL
//const PRACTICE_ID = 'SamsPractice'; // Hardcoded practice ID

export const getData = async (practiceId) => {
  try {
    const response = await fetch(`${BASE_URL}/api/engagement/${practiceId}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching engagement scenarios:', error);
    throw error;
  }
};

export const updateData = async (flow, practiceId) => {
  try {
    const response = await fetch(`${BASE_URL}/api/engagement`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...flow, practiceId: practiceId }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error updating engagement scenario:', error);
    throw error;
  }
};

// Helper function to convert our data structure to React Flow nodes and edges
export const flowToNodesEdges = (flow) => {
  const nodes = flow.sequence.map((item, index) => ({
    id: item.id,
    type: 'message',
    position: { x: index * 250, y: 100 },
    data: {
      title: `Step ${index + 1}`,
      status: item.action,
      copy: item.copy
    }
  }));

  const edges = flow.sequence.reduce((acc, item, index) => {
    if (item.next !== null && index < flow.sequence.length - 1) {
      acc.push({
        id: `e${item.id}-${flow.sequence[index + 1].id}`,
        source: item.id,
        target: flow.sequence[index + 1].id,
        label: `${item.next}`,
        type: 'smoothstep'
      });
    }
    return acc;
  }, []);

  return { nodes, edges };
};

// Helper function to convert React Flow nodes and edges back to our data structure
export const nodesEdgesToFlow = (flowId, name, type, trigger, patientType, nodes, edges) => {
    const sequence = nodes.map(node => ({
      id: node.id,
      action: node.data.status,
      copy: node.data.copy,
      next: null,
    }));
  
    edges.forEach(edge => {
      const sourceIndex = sequence.findIndex(item => item.id === edge.source);
      if (sourceIndex !== -1) {
        sequence[sourceIndex].next = parseInt(edge.label);
      }
    });
  
    return {
      id: flowId,
      name,
      type,
      trigger,
      patientType: type === 'Confirm' ? patientType : undefined,
      sequence,
    };
  };
  

  export const deleteFlow = async (flowId, practiceId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/engagement/${practiceId}/${flowId}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error deleting engagement scenario:', error);
      throw error;
    }
  };