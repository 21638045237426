import React, { useState, useEffect } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/Select';
import { Shield } from 'lucide-react';
import { GetPracticeLocations } from '../services/ActivityService';


const formatLocationData = (inputList, practiceIdAdmin1) => {
  console.log("inputList ", inputList);
  const formattedData = inputList.map((item, index) => {
    const [name, address] = item.split(',', 2);
    const location = address.trim().split(',').slice(-2).join(',').trim();
    return {
      id: `${practiceIdAdmin1}${index + 1}`,
      name: name.trim(),
      location: location
    };
  });

  let adminName = practiceIdAdmin1;
  if(adminName === "Sams Practice")
  {
    adminName = "Smiles Dental"
  }

  formattedData.unshift({
    id: practiceIdAdmin1,
    name: adminName,
    location: 'n/a'
  });

  return formattedData;
};

const AdminPracticeSelector = ({ currentPracticeId, onPracticeChange }) => {
  const [practices, setPractices] = useState([]);

  useEffect(() => {
    const fetchPractices = async () => {
      const practiceIdAdmin = localStorage.getItem('practiceIdAdmin');
      try {
        const practiceLocations = await GetPracticeLocations(practiceIdAdmin);
        const formattedPractices = formatLocationData(practiceLocations, practiceIdAdmin);
        setPractices(formattedPractices);
      } catch (error) {
        console.error("Error fetching practice locations:", error);
        // Handle error (e.g., show error message to user)
      }
    };

    fetchPractices();
  }, []);

  const currentPractice = practices.find(p => p.id === currentPracticeId) || practices[0];

  if (!currentPractice) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
    <div className="flex items-center gap-2">
      <Shield className="h-5 w-5 text-blue-500" title="Admin Portal" />
      <Select onValueChange={onPracticeChange} defaultValue={currentPracticeId}>
        <SelectTrigger className="border-none bg-transparent p-0">
          <SelectValue>
            <div className="flex flex-col justify-center">
              <span className="text-lg font-semibold leading-tight">{currentPractice.name}</span>
              <span className="text-sm text-muted-foreground leading-tight">{currentPractice.location}</span>
            </div>
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          {practices.map((practice) => (
            <SelectItem key={practice.id} value={practice.id}>
              <div className="flex flex-col">
                <span className="font-semibold">{practice.name}</span>
                <span className="text-sm text-muted-foreground">{practice.location}</span>
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default AdminPracticeSelector;