import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Handle, Position } from '@xyflow/react';

const Message = ({ data, isConnectable, id }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedCopy, setEditedCopy] = useState(data.copy);
  const ignoreBlurRef = useRef(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    setEditedCopy(data.copy);
  }, [data.copy]);

  const handleDoubleClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleBlur = useCallback(() => {
    console.log("ignoreBlurRef.current ", ignoreBlurRef.current)
    if (ignoreBlurRef.current) {
      console.log("ignoreBlurRef")
      ignoreBlurRef.current = false;
      textareaRef.current.focus(); // Re-focus the textarea
      return;
    }
    setIsEditing(false);
    if (typeof data.onChange === 'function') {
      data.onChange(id, editedCopy);
    }
  }, [data, id, editedCopy]);

  const handleChange = useCallback((e) => {
    setEditedCopy(e.target.value);
  }, []);

  const handleDeleteClick = useCallback(() => {
    if (typeof data.onDelete === 'function') {
      data.onDelete(id);
    }
  }, [ id, data]);

  // Variable options based on flow type
  const variableOptions = data.flowType === 'Confirm' ? [
    { label: 'PatientName', placeholder: '{PatientName}' },
    { label: 'TimeOfAppointment', placeholder: '{TimeOfAppointment}' },
  ] : [
    { label: 'PatientName', placeholder: '{PatientName}' },
    { label: 'TimeSinceAppointment', placeholder: '{TimeSinceAppointment}' },
    { label: 'OpenAppointmentTime', placeholder: '{OpenAppointmentTime}' },
  ];

  const insertVariable = (placeholder) => {
    const textarea = textareaRef.current;
    if (textarea) {
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      const newValue = editedCopy.substring(0, startPos) + placeholder + editedCopy.substring(endPos);
      setEditedCopy(newValue);

      // Move cursor to after the inserted placeholder
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = startPos + placeholder.length;
        textarea.focus(); // Ensure textarea remains focused
      }, 0);
    }
  };

  return (
    <div className="relative px-4 py-2 shadow-md rounded-md bg-white border-2 border-gray-200">
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
      {/* Delete Icon */}
      <button
        onClick={handleDeleteClick}
        className="absolute top-1 right-1 text-red-500 hover:text-red-700"
        title="Delete Node"
      >
        &#10005;
      </button>
      <div className="font-bold">{data.title}</div>
      <div className="text-sm text-gray-500">{data.status}</div>
      {isEditing ? (
        <div>
          {/* Variable Options */}
          <div className="flex space-x-2 mb-2">
            {variableOptions.map((option) => (
              <span
                key={option.placeholder}
                onMouseDownCapture={(e) => {
                  e.preventDefault(); // Prevent focus change
                  ignoreBlurRef.current = true;
                  insertVariable(option.placeholder);
                }}
                tabIndex="-1" // Prevent button from being focusable
                className="px-2 py-1 bg-gray-200 rounded text-sm"
              >
                {option.label}
              </span>
            ))}
          </div>
          <textarea
            ref={textareaRef}
            value={editedCopy}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus
            className="w-full p-2 border rounded"
          />
        </div>
      ) : (
        <div
          onDoubleClick={handleDoubleClick}
          className="min-h-[20px] cursor-pointer" // Add min height and cursor pointer
        >
          {data.copy || <span className="text-gray-400">Double-click to edit</span>}
        </div>
      )}
      <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
    </div>
  );
};

export default Message;
