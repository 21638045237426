import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Badge } from '../ui/Badge';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import ChatInterface from './ChatInterface';
import ChatSidebar from './ChatSidebar';
import {Car, Send, AlertTriangle} from 'lucide-react';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../ui/Card"
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { getConversationsByPractice } from '../services/messagesService';
import { sendMessage } from '../services/patientsService'; 
import { useNavigate } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/Tabs";
import { Checkbox } from "../ui/Checkbox"; // Import the Checkbox component
import { fetchCallData, fetchExceptions, toggleResolved } from '../services/userInfoService';
import { Pagination, PaginationContent, PaginationItem, PaginationPrevious, PaginationNext, PaginationLink, PaginationEllipsis } from '../ui/Pagination';

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../ui/Table"

const Portal = () => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [name, setName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('111')
  const [convoId, setConvoId] = useState('')
  const [globalPracticeId, setGlobalPracticeId] = useState('')
  const navigate = useNavigate();
  const cardContentRef = useRef(null);
  const isUserScrollingRef = useRef(false);
  const [escalatedCases, setEscalatedCases] = useState([]);
  const [currentPageEscalation, setCurrentPageEscalation] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchEscalatons = useCallback(async () => {
    const practiceId = localStorage.getItem('practiceId') || '';
  if (!practiceId) {
    console.log('No practiceId found in local storage');
    navigate('/');
    return;
  }
    setGlobalPracticeId(practiceId);

    try {
      console.log("currentPageEscalation ", currentPageEscalation)
      const data = await fetchExceptions(practiceId, currentPageEscalation, 10);
      setTotalPages(data.pagination.totalPages);
      const sortedCalls = data.calls.sort((a, b) => new Date(b.time) - new Date(a.time));
      const modifiedCalls = sortedCalls.map((call) => ({
        ...call,
        resolved: call.bResolved, // Map 'bResolved' to 'resolved'
      }));
      setEscalatedCases(modifiedCalls);  // Assuming the response structure is { calls: [...], pagination: {...} }

    } catch (error) {
      console.error('Error fetching data:', error);
      setEscalatedCases([]);
    }
  }, [navigate, currentPageEscalation]);;

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';

    if (true) {
         window.pylon = {
             chat_settings: {
                 app_id: "98e8ee10-f8ab-4d78-837b-bead4ef90b72",
                 email: "user.email",
                 name: "user.fullName",
                
             },
         };
     }

    const fetchConversations = async () => {

      const practiceId = localStorage.getItem('practiceId') || '';
    if (!practiceId) {
      console.log('No practiceId found in local storage');
      navigate('/');
      return;
    }
      setGlobalPracticeId(practiceId);
      console.log("convoId ",  convoId)
    getConversationsByPractice(practiceId, currentPage, 10, searchTerm, true)
      .then(data => {
        console.log("data ", data)
        const modifiedConversations = data
        .map(convo => ({
            id: convo.id,
            name: convo.name,
            lastMessage: convo.message[convo.message.length - 1].message,
            messages: convo.message,
            stage: convo.stage
          }));
        console.log("modifiedConversations ", modifiedConversations)
        setConversations(modifiedConversations);

        if(convoId !== '')
        {
          const specificConversation = modifiedConversations.find(convo => convo.id === convoId);
          if(specificConversation)
          {
            console.log("did we find it", specificConversation.messages)
            setMessages(specificConversation.messages);
          }
        }
      })
      .catch(error => setError(error));

    };


    fetchEscalatons();
    fetchConversations();
    const intervalId = setInterval(fetchConversations, 1000);


    if (true) {
      const email = localStorage.getItem('email') || '';
      window.pylon = {
          chat_settings: {
              app_id: "98e8ee10-f8ab-4d78-837b-bead4ef90b72",
              email: email,
              name: globalPracticeId
          },
      };
  }


    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
    
  }, [searchTerm, currentPage, messages, convoId, navigate, globalPracticeId, currentPageEscalation, fetchEscalatons]);

  const handleConversationClick = (conversation) => {
    console.log("handleConversationClick", conversation);
    setConvoId(conversation.id);
    setPhoneNumber(conversation.id);
    setName(conversation.name)
    //setMessages(conversation.messages);
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    const practiceId = localStorage.getItem('practiceId') || '';
    if (!practiceId) {
      console.log('No practiceId found in local storage');
      navigate('/');
      return;
    }
    if (newMessage.trim() !== '') {
      sendMessage(newMessage, practiceId, phoneNumber, true); // Call the service function
      setNewMessage('');
    }
  };

  useEffect(() => {
    const handleUserScroll = () => {
      const cardContent = cardContentRef.current;
      if (cardContent) {
        const isNearBottom = cardContent.scrollHeight - cardContent.scrollTop <= cardContent.clientHeight + 50;
        isUserScrollingRef.current = !isNearBottom;
      }
    };

    const cardContent = cardContentRef.current;
    if (cardContent) {
      cardContent.addEventListener('scroll', handleUserScroll);
      return () => {
        cardContent.removeEventListener('scroll', handleUserScroll);
      };
    }
  }, []);

  useEffect(() => {
    const cardContent = cardContentRef.current;
    if (cardContent && !isUserScrollingRef.current) {
      cardContent.scrollTop = cardContent.scrollHeight;
    }
  }, [messages]);

  const handleResolvedChange = (id, checked) => {

    toggleResolved(id, globalPracticeId);

    setEscalatedCases(prevCases =>
      prevCases.map(caseItem =>
        caseItem.id === id ? { ...caseItem, resolved: checked } : caseItem
      )
    );
  };

  const handlePageChange = (page) => {
    setCurrentPageEscalation(page);
    fetchEscalatons();
  };

  return (
    <div className="grid h-screen w-screen md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
        <div className="hidden border-r bg-muted/40 md:block">
            <Sidebar practiceId={globalPracticeId}/>
        </div>
        <div>
            <MainContent title="Portal">
                <Tabs defaultValue="chat" className="w-full h-full">
                    <TabsList>
                        <TabsTrigger value="chat">Chat</TabsTrigger>
                        <TabsTrigger value="escalations">
                            Escalations
                        </TabsTrigger>
                    </TabsList>
                    <TabsContent value="chat" className="h-full">
                        <div className="flex h-full flex-1">
                            <div className="w-1/6 border-r"> 
                                <ChatSidebar
                                    conversations={conversations}
                                    onConversationClick={handleConversationClick}
                                    searchTerm={searchTerm}
                                    setSearchTerm={setSearchTerm}
                                />       
                            </div>

                            <div className="flex-1 flex flex-col">
                                <Card className="flex-1 flex flex-col">
                                    <CardHeader>
                                        <CardTitle>{name}</CardTitle>
                                        {/* <CardDescription>Relevant metadata here</CardDescription> */}
                                    </CardHeader>
                                    <CardContent ref={cardContentRef} className="flex-1 overflow-y-auto">
                                        {messages.map((message, index) => (
                                            <div key={index} className={`mb-4 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
                                                <Badge variant={message.sender === 'user' ? 'default' : 'secondary'}>
                                                    {message.sender === 'user' ? 'You' : 'Newton AI'}
                                                </Badge>
                                                <p>{message.message}</p>
                                            </div>
                                        ))}
                                    </CardContent>
                                    <CardFooter>
                                        <form onSubmit={handleSendMessage} className="flex w-full gap-2">
                                            <Input
                                                placeholder="Type your message..."
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                            />
                                            <Button type="submit">
                                                <Send className="h-4 w-4" />
                                                Send
                                            </Button>
                                        </form>
                                    </CardFooter>
                                </Card>
                            </div>
                        </div>
                    </TabsContent>
                    <TabsContent value="escalations" className="h-full overflow-y-auto">
                      <div className="h-[calc(100vh-200px)] overflow-y-auto">
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>Summary</TableHead>
                                            <TableHead>Phone Number</TableHead>
                                            <TableHead>Name</TableHead>
                                            <TableHead>Time</TableHead>
                                            <TableHead>Resolved?</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {console.log('escalatedCases:', escalatedCases)}
                                        {escalatedCases.map((caseItem) => (
                                            <TableRow key={caseItem.id}>
                                                <TableCell className={caseItem.resolved ? 'line-through' : ''}>
                                                    {caseItem.summary}
                                                </TableCell>
                                                <TableCell className={caseItem.resolved ? 'line-through' : ''}>
                                                    {caseItem.phoneNumber}
                                                </TableCell>
                                                <TableCell className={caseItem.resolved ? 'line-through' : ''}>
                                                    {caseItem.name}
                                                </TableCell>
                                                <TableCell className={caseItem.resolved ? 'line-through' : ''}>
                                                    {caseItem.time}
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={caseItem.resolved}
                                                        onCheckedChange={(checked) => handleResolvedChange(caseItem.id, checked)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <div className="mt-4 flex justify-center">
                                <Pagination>
                                  <PaginationContent>
                                    <PaginationItem>
                                      <PaginationPrevious 
                                        onClick={() => handlePageChange(Math.max(currentPageEscalation - 1, 1))}
                                        disabled={currentPageEscalation === 1}
                                      />
                                    </PaginationItem>
                                    <PaginationItem>
                                      <PaginationLink 
                                        isActive={true}
                                        onClick={() => handlePageChange(currentPageEscalation)}
                                      >
                                        {currentPageEscalation}
                                      </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem>
                                      <PaginationEllipsis />
                                    </PaginationItem>
                                    <PaginationItem>
                                      <PaginationNext 
                                        onClick={() => handlePageChange(currentPageEscalation + 1)}
                                      />
                                    </PaginationItem>
                                  </PaginationContent>
                                </Pagination>
                              </div>
                                </div>
                    </TabsContent>
                </Tabs>
            </MainContent>
        </div>
    </div>
  );
};

export default Portal;