// src/views/EngagementPage.js

import React from 'react';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import Engagement from './Engagement'; // Adjust the import path as needed

const EngagementPage = () => {
  const practiceIdGlobal = localStorage.getItem('practiceId') || '';

  return (
    <div className="grid min-h-screen w-full md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
      {/* Sidebar */}
      <div className="hidden border-r bg-muted/40 md:block">
        <Sidebar practiceId={practiceIdGlobal} />
      </div>

      {/* Main Content */}
      <div className="flex flex-col min-h-screen">
        <MainContent title="Engagement Flows">
          {/* Engagement Component */}
          <div className="p-4">
            <Engagement practiceId={practiceIdGlobal} />
          </div>
        </MainContent>
      </div>
    </div>
  );
};

export default EngagementPage;
