import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Home, MessageSquare, MessageCircle, Settings, User, Package2, Play, DoorOpen } from 'lucide-react';
import SettingsDialog from '../ui/SettingsDialog';
import { Badge } from '../ui/Badge';
import { GetUnreadMessagesCount } from '../services/ActivityService';
import NewtonLogo from '../assets/newton-logo.png'; // Add this import

function Sidebar({ practiceId = null }) {
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [unreadMessages, setUnreadMessages] = useState(0);

    useEffect(() => {
        async function fetchUnreadMessages() {
            if (practiceId) {
                try {
                    const result = await GetUnreadMessagesCount(practiceId);
                    setUnreadMessages(result.unreadMessages);
                } catch (error) {
                    console.error('Failed to fetch unread messages:', error);
                    // Handle the error appropriately
                }
            }
        }

        fetchUnreadMessages();
    }, [practiceId]);

    const openSettings = () => {
        setIsSettingsOpen(true);
    };

    const closeSettings = () => {
        setIsSettingsOpen(false);
    };

    return (
        <div className="flex h-full flex-col gap-2">
            <div className="flex items-center border-b px-4 lg:h-[60px] lg:px-6">
                    <NavLink className="flex items-center gap-2 font-semibold" to="/">
                        <img src={NewtonLogo} alt="Newton Logo" className="h-6 w-auto" />
                    </NavLink>
            </div>
            <div className="flex-1">
                <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
                    <NavLink
                        to="/home"
                        className={({ isActive }) =>
                            `flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-primary ${
                                isActive ? 'bg-muted text-primary' : 'text-muted-foreground'
                            }`
                        }
                    >
                        <Home className="h-4 w-4" />
                        Home
                    </NavLink>
                    <NavLink
                        to="/portal"
                        className={({ isActive }) =>
                            `flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-primary ${
                                isActive ? 'bg-muted text-primary' : 'text-muted-foreground'
                            }`
                        }
                    >
                        <DoorOpen className="h-4 w-4" />
                        Portal
                        {unreadMessages > 0 && (
                            <Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
                                {unreadMessages}
                            </Badge>
                        )}
                    </NavLink>
                    <NavLink
                        to="/conversations"
                        className={({ isActive }) =>
                            `flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-primary ${
                                isActive ? 'bg-muted text-primary' : 'text-muted-foreground'
                            }`
                        }
                    >
                        <MessageCircle className="h-4 w-4" />
                        Conversations
                    </NavLink>
                    {practiceId === "VarinosDemo" && (
                        <div className="mt-auto px-2 pb-4">
                            <NavLink
                                to={`/runDemo`}
                                className={({ isActive }) =>
                                    `flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-primary ${
                                        isActive ? 'bg-muted text-primary' : 'text-muted-foreground'
                                    }`
                                }
                            >
                                <Play className="h-4 w-4" />
                                Run Demo
                            </NavLink>
                        </div>
                    )}
                    {/* {(practiceId === "towndental" || practiceId === "acevedo" || practiceId === "CherryHill" || practiceId === "Dosset" || practiceId === "AtlanticDentalArts" || practiceId.toLowerCase().includes("smiles") || ['p1', 'p2', 'p3', 'pAdmin'].includes(practiceId))  && (
                        <NavLink
                            to={`/calllog`}
                            className={({ isActive }) =>
                                `flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-primary ${
                                    isActive ? 'bg-muted text-primary' : 'text-muted-foreground'
                                }`
                            }
                        >
                            <Play className="h-4 w-4" />
                            Call Log
                        </NavLink>
                    )} */}
                    {practiceId === "Dosset2" && (
                    <NavLink
                        to="/blocks"
                        className={({ isActive }) =>
                        `flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-primary ${
                            isActive ? 'bg-muted text-primary' : 'text-muted-foreground'
                        }`
                        }
                    >
                        <Package2 className="h-4 w-4" />
                        Blocks
                    </NavLink>
                    )}
                    {['Sams Practice', 'Smiles Dental Admin', 'SmilesDental1', 'SmilesDental2', 'acevedo', 'p1', 'p2', 'p3', 'pAdmin'].includes(practiceId)  && (
                    <NavLink
                        to="/patients_"
                        className={({ isActive }) =>
                        `flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-primary ${
                            isActive ? 'bg-muted text-primary' : 'text-muted-foreground'
                        }`
                        }
                    >
                        <User className="h-4 w-4" />
                        Patients
                    </NavLink>
                    )}
                </nav>
            </div>
        </div>
    );
}

export default Sidebar;