const BASE_URL = process.env.REACT_APP_API_URL || 'https://dentalexpressserver.azurewebsites.net';
//const BASE_URL = 'http://localhost:3001'

function fetchFromAPI(endpoint, options = {}) {
    return fetch(`${BASE_URL}${endpoint}`, options)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        });
}

async function getPracticeStats(practiceId) {
    try {
      const endpoint = `/api/practices/stats/${practiceId}`;
      const practiceStats = await fetchFromAPI(endpoint);
      if (!practiceStats) {
        throw new Error('Practice stats not found for the provided practice ID');
      }
      return practiceStats;
    } catch (error) {
      console.error('Error fetching practice stats:', error);
      throw error;
    }
  }

  async function getBugList() {
    try {
      const endpoint = '/api/buglist';
      
      const bugList = await fetchFromAPI(endpoint);
      console.log("getBugListresponse ", bugList);
      
      if (!Array.isArray(bugList)) {
        throw new Error('Invalid bug list data received');
      }
      
      return bugList;
    } catch (error) {
      console.error('Error fetching bug list:', error);
      throw error;
    }
  }
  
  

async function getActivities(practiceIdOrIds) {
    try {
      let endpoint;
      
      if (Array.isArray(practiceIdOrIds)) {
        endpoint = `/api/activities?practiceIds=${practiceIdOrIds.join(',')}`;
      } else {
        endpoint = `/api/activities?practiceId=${practiceIdOrIds}`;
      }
      
      const activities = await fetchFromAPI(endpoint);
  
      if (!activities) {
        throw new Error('Activities not found for the provided practice ID(s)');
      }
  
      return activities;
    } catch (error) {
      console.error('Error fetching activities:', error);
      throw error;
    }
}

async function confirmActivity(phoneNumber, name, practiceId, documentId, time, previousTime, patientId, column) {
    try {

        console.log("previousTime", previousTime)

        const endpoint = '/api/confirm';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phoneNumber, name, practiceId, documentId, time, previousTime, patientId, column }),
        };

        const response = await fetchFromAPI(endpoint, options);

        return response;
    } catch (error) {
        console.error('Error confirming activity:', error);
        throw error;
    }
}

async function ManualHandleActivity(name, practiceId) {
    try {

        const endpoint = '/api/manual';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, practiceId}),
        };

        const response = await fetchFromAPI(endpoint, options);

        return response;
    } catch (error) {
        console.error('Error confirming activity:', error);
        throw error;
    }
}

async function GetPracticeStats(practiceId) {
    try {
        // Construct the endpoint URL with the practiceId
        const endpoint = `/api/practices/stats/${practiceId}`;

        // Set up the options for the fetch request
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        // Call the API and get the response
        const response = await fetchFromAPI(endpoint, options);

        // Return the response data
        return response;
    } catch (error) {
        console.error('Error fetching practice statistics:', error);
        throw error;
    }
}

async function GetPracticebAdmin(practiceId) {
  try {
      // Construct the endpoint URL with the practiceId
      const endpoint = `/api/practices/bAdmin/${practiceId}`;

      // Set up the options for the fetch request
      const options = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
      };

      // Call the API and get the response
      const response = await fetchFromAPI(endpoint, options);

      // Return the response data
      return response.bAdmin;
  } catch (error) {
      console.error('Error fetching practice statistics:', error);
      throw error;
  }
}

async function GetPracticeLocations(practiceId) {
  try {
      // Construct the endpoint URL with the practiceId
      const endpoint = `/api/practices/practices/${practiceId}`;

      // Set up the options for the fetch request
      const options = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
      };

      // Call the API and get the response
      const response = await fetchFromAPI(endpoint, options);
      
      // Return the response data
      return response.practices;
  } catch (error) {
      console.error('Error fetching practice statistics:', error);
      throw error;
  }
}

async function GetUnreadMessagesCount(practiceId) {
  try {
      // Construct the endpoint URL with the practiceId
      const endpoint = `/api/unread-messages/${practiceId}`;

      // Set up the options for the fetch request
      const options = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
      };

      // Call the API and get the response
      const response = await fetchFromAPI(endpoint, options);

      // Return the response data
      return response;
  } catch (error) {
      console.error('Error fetching unread messages count:', error);
      throw error;
  }
}

async function getThrowsByPracticeId(practiceIdOrIds) {
    try {
      let endpoint;
      
      if (Array.isArray(practiceIdOrIds)) {
        endpoint = `/api/throws?practiceIds=${practiceIdOrIds.join(',')}`;
      } else {
        endpoint = `/api/throws?practiceId=${practiceIdOrIds}`;
      }
      
      const throwDocuments = await fetchFromAPI(endpoint);
      console.log("Throw documents:", throwDocuments);
      
      if (!throwDocuments || throwDocuments.length === 0) {
        throw new Error('No documents found for the given practiceId(s)');
      }
      
      return throwDocuments;
    } catch (error) {
      console.error('Error fetching throw documents:', error);
      throw error;
    }
  }

async function markThrowDocumentResolved(document) {
    try {

        const endpoint = '/api/updateActedOn';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                practiceId: document.practiceId,
                phoneNumber: document.phoneNumber 
            }),
        };

        const response = await fetchFromAPI(endpoint, options);

        return response;
    } catch (error) {
        console.error('Error confirming activity:', error);
        throw error;
    }
}

async function addToWaitlist(name, preferences, practiceId) {
  try {
    const updatedPreferences = preferences.trim() === '' ? 'any time works' : preferences;
    console.log("updatedPreferences ", updatedPreferences)
      const endpoint = '/add-to-waitlist';
      const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, preferences: updatedPreferences, practiceId }),
      };

      const response = await fetchFromAPI(endpoint, options);
      console.log("response23 ", response)

      if (!response) {
          throw new Error('Failed to add patient to waitlist');
      }

      return response;
  } catch (error) {
      console.error('Error adding patient to waitlist:', error);
      throw error;
  }
}

async function deleteWaitlistPatient(patientId, practiceId) {
  try {
    const endpoint = '/delete-from-waitlist';
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ patientId, practiceId }),
    };

    const response = await fetchFromAPI(endpoint, options);
    
    if (!response) {
      throw new Error('Failed to delete patient from waitlist');
    }

    return response;
  } catch (error) {
    console.error('Error deleting patient from waitlist:', error);
    throw error;
  }
}



async function getWaitlistPatients(practiceId) {
  try {
      console.log("getWaitlistPatients")
      const endpoint = `/api/getwaitlist?practiceId=${practiceId}`;
      
      const waitlistPatients = await fetchFromAPI(endpoint);

      if (!waitlistPatients) {
          throw new Error('Waitlist patients not found for the provided practice ID');
      }

      return waitlistPatients;
  } catch (error) {
      console.error('Error fetching waitlist patients:', error);
      throw error;
  }
}

export async function getKnowledgeBase(practiceId) {
  try {
    console.log("getKnowledgeBase");
    const endpoint = `/api/getknowledgebase?practiceId=${practiceId}`;
    
    const knowledgeBase = await fetchFromAPI(endpoint);
    
    if (!knowledgeBase) {
      throw new Error('Knowledge base not found for the provided practice ID');
    }
    
    return knowledgeBase;
  } catch (error) {
    console.error('Error fetching knowledge base:', error);
    throw error;
  }
}

export async function updateKnowledgeBase(practiceId, updatedFacts) {
  try {
    const endpoint = '/api/updateknowledgebase';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ practiceId, facts: updatedFacts }),
    };

    const response = await fetchFromAPI(endpoint, options);

    if (!response) {
      throw new Error('Failed to update knowledge base');
    }

    return response;
  } catch (error) {
    console.error('Error updating knowledge base:', error);
    throw error;
  }
}

export const getAllPatientNames = async (practiceId) => {
  try {
    console.log("getAllPatientNames practiceId ", practiceId)
    const endpoint = `/api/patients/names/${practiceId}`;
    const patientNames = await fetchFromAPI(endpoint);
    return patientNames;
  } catch (error) {
    console.error('Error fetching patient names:', error);
    throw error;
  }
};

const getAnalyticsData = (monthInterval, practiceId = 'default-practice') => {
  let data;

  if(practiceId === "wynn")
  {
    switch(monthInterval) {
      case 1:
        data = {
          totalHygieneOccupancy: 81.42, // made up
          sameDayBooking: 61.39,
          patientsBookedForNextCleaning: 75.88,
          brokenAppointments: 0,
          reactivationMessages: 325,
          reactivationConversationPercentage: 0,
          newPatientsBookedByHemingway: 9,
          callsAnsweredByHemingway: 0
        };
        break;
      case 3:
        data = {
          totalHygieneOccupancy: 81.89, // made up
          sameDayBooking: 61.35,
          patientsBookedForNextCleaning: 72.86,
          brokenAppointments: 0,
          reactivationMessages: 623,
          reactivationConversationPercentage: 0,
          newPatientsBookedByHemingway: 24,
          callsAnsweredByHemingway: 0
        };
        break;
      case 6:
      default:
        data = {
          totalHygieneOccupancy: 82.61,
          sameDayBooking: 61.96,
          patientsBookedForNextCleaning: 70.43,
          brokenAppointments: 0,
          reactivationMessages: 1356,
          reactivationConversationPercentage: 0,
          newPatientsBookedByHemingway: 50,
          callsAnsweredByHemingway: 0
        };
    }
    data.appointmentStatus = {
      UnschedList: 8,
      Complete: 13,
      Upcoming: 19,
      Rescheduled: 9,
      Broken: 1
    };
    return data;
  }
  
  switch(monthInterval) {
    case 1:
      data = {
        totalHygieneOccupancy: 70,
        sameDayBooking: 75,
        patientsBookedForNextCleaning: 80,
        brokenAppointments: 15,
        reactivationMessagePerDay: 35,
        reactivationConversationPercentage: 20,
        newPatientsBookedByHemingway: 30,
        callsAnsweredByHemingway: 150
      };
      break;
    case 3:
      data = {
        totalHygieneOccupancy: 72,
        sameDayBooking: 70,
        patientsBookedForNextCleaning: 82,
        brokenAppointments: 18,
        reactivationMessagePerDay: 38,
        reactivationConversationPercentage: 18,
        newPatientsBookedByHemingway: 85,
        callsAnsweredByHemingway: 320
      };
      break;
    case 6:
    default:
      data = {
        totalHygieneOccupancy: 65,
        sameDayBooking: 69,
        patientsBookedForNextCleaning: 85,
        brokenAppointments: 22,
        reactivationMessagePerDay: 40,
        reactivationConversationPercentage: 15,
        newPatientsBookedByHemingway: 100,
        callsAnsweredByHemingway: 480
      };
  }

  return data;
};


export { getActivities, confirmActivity, ManualHandleActivity, GetPracticeStats, getThrowsByPracticeId, markThrowDocumentResolved, getPracticeStats, addToWaitlist, getWaitlistPatients, GetUnreadMessagesCount, getAnalyticsData, deleteWaitlistPatient, GetPracticeLocations, GetPracticebAdmin, getBugList };