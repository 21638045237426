// src/views/Home.js
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Badge } from '../ui/Badge';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import { Input } from '../ui/Input'; // Import the custom Input component
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "../ui/Table"
  import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
  } from "../ui/Tabs"
  import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "../ui/Dialog"
  import { getActivities, confirmActivity, ManualHandleActivity, GetPracticeStats, getThrowsByPracticeId, markThrowDocumentResolved, addToWaitlist, getWaitlistPatients, getAllPatientNames, deleteWaitlistPatient } from '../services/ActivityService';
  import { useNavigate } from 'react-router-dom'; 
  import AnalyticsDashboard from  './Analytics';
  import CallLog from './CallLog'
  import { Filter as FilterIcon, Download as ExportIcon, Pencil, Trash2 } from 'lucide-react';
  import { GetPracticebAdmin, GetPracticeLocations, getBugList } from '../services/ActivityService'
  import  BugList  from './BugList'

  function generatePracticeList(practiceId, practicesList) {
    return practicesList.map((_, index) => `${practiceId}${index + 1}`);
  }


const Home = () => {
    const [activities, setActivities] = useState([]);
    const [practiceIdGlobal, setPracticeIdGlobal] = useState('');
    const [patientName, setPatientName] = useState('');
    const [preferences, setPreferences] = useState('');
    const [activeTab, setActiveTab] = useState('booked');
    const [waitlist, setWaitlist] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const today = new Date();
    const [allPatientNames, setAllPatientNames] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [practiceIdAdmin, setPracticeIdAdmin] = useState(localStorage.getItem('practiceIdAdmin') || '');
    const [editingPatient, setEditingPatient] = useState(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    //const navigate = useNavigate();

    const handleEditClick = (patient) => {
        setEditingPatient(patient);
        setIsEditDialogOpen(true);
    };

    const handleEditSubmit = async () => {
        try {
            await addToWaitlist(editingPatient.name, editingPatient.preferenceString, practiceIdGlobal);
            console.log('Patient updated successfully');
            setIsEditDialogOpen(false);
            await fetchWaitlist();
        } catch (error) {
            console.error('Failed to update patient:', error);
            alert('Error occurred while updating patient');
        }
    };

    const handleDeleteClick = async (patientId) => {
        if (window.confirm('Are you sure you want to delete this patient from the waitlist?')) {
            try {
                await deleteWaitlistPatient(patientId, practiceIdGlobal);
                console.log('Patient deleted successfully');
                await fetchWaitlist();
            } catch (error) {
                console.error('Failed to delete patient:', error);
                alert('Error occurred while deleting patient');
            }
        }
    };

    const fetchWaitlist = async () => {
        const practiceId = localStorage.getItem('practiceId');
        if (!practiceId) {
            console.log('No practiceId found in local storage');
            return;
        }
    
        try {
            const waitlistData = await getWaitlistPatients(practiceId);
            setWaitlist(waitlistData); 
            console.log("Updated waitlistData:", waitlistData);
        } catch (error) {
            console.error('Error fetching waitlist:', error);
        }
    };
    
    const fetchAllPatientNames = async () => {
        const practiceId = localStorage.getItem('practiceId');
        if (!practiceId) {
            console.log('No practiceId found in local storage');
            return;
        }
    
        try {
            const patientNames = await getAllPatientNames(practiceId);
            setAllPatientNames(patientNames);
        } catch (error) {
            console.error('Error fetching all patient names:', error);
        }
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            const practiceId = localStorage.getItem('practiceId');
            if (practiceId) {
                let bAdmin = await GetPracticebAdmin(practiceId)
                setPracticeIdGlobal(practiceId);
                console.log("practiceId23 ", practiceId)
                try {
                    let actPracticeId = practiceId;
                    if(practiceId === "pAdmin")
                    {
                        actPracticeId = ["p1", "p2", "p3"]
                    }
                    else if(bAdmin)
                    {
                        let practicesList = await GetPracticeLocations(practiceId);
                        actPracticeId = generatePracticeList(practiceId, practicesList)
                        console.log("actPracticeId ", actPracticeId)

                    }
                    let activitiesData = await getActivities(actPracticeId);
                    const FILTER_ACTED_ON_PRACTICE_IDS = ['wynn', 'starBrite', 'starBriteFremont', 'GraceDental'];
                    if (FILTER_ACTED_ON_PRACTICE_IDS.includes(practiceId)) {
                        activitiesData = activitiesData.filter(activity => activity.ActedOn === true);
                    }
                    const sortedActivities = activitiesData.sort((a, b) => b._ts - a._ts);
                    setActivities(sortedActivities);
                    console.log("Fetched activities:", sortedActivities);
    
                    await fetchWaitlist();
                    await fetchAllPatientNames();
                } catch (error) {
                    console.error('Error fetching initial data:', error);
                }
            } else {
                console.log('No practiceId found in local storage');
            }
        };
    
        fetchInitialData();
    }, []);

    const handlePatientNameChange = (event) => {
        // Check if the argument is an event or a direct value
        const value = typeof event === 'object' ? event.target.value : event;
      
        setPatientName(value);
        console.log("Updated suggestions state: ", suggestions); 
        if (typeof value !== 'string' || value.length < 2) {
          setSuggestions([]);
          return;
        }
      
        const filteredNames = allPatientNames
          .filter(name => name.toLowerCase().includes(value.toLowerCase()))
          .slice(0, 10);

        console.log("filteredNames ", filteredNames)
      
        setSuggestions(filteredNames);
      };
      
      const handleSuggestionClick = (name) => {
        setPatientName(name);
        setSuggestions([]);
      };

    const handleSubmit = async () => {
        console.log('Patient Name:', patientName);
        console.log('Preferences:', preferences);
        try {
            await addToWaitlist(patientName, preferences, practiceIdGlobal);
            console.log('Patient added to waitlist successfully');
            setIsDialogOpen(false);  // Close the dialog
            setPatientName('');  // Clear the form
            setPreferences('');
            await fetchWaitlist();  // Refresh the waitlist immediately
        } catch (error) {
            console.error('Failed to add patient to waitlist:', error);
            alert('Error occured, try rephrasing your preferences');
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        
        // Check if the dateString matches the specific format "YYYY-MM-DDTHH"
        if (/^\d{4}-\d{2}-\d{2}T\d{2}$/.test(dateString)) {
          const [year, month, day, hour] = dateString.split(/[-T]/);
          const date = new Date(year, month - 1, day, hour);
          return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
          });
        }
        
        // For all other formats, use the original parsing logic
        return new Date(dateString).toLocaleString([], {
          month: 'short', 
          day: 'numeric', 
          hour: 'numeric', 
          minute: '2-digit',
          hour12: true
        });
      };
    
      function unixToFormattedDate(unixTimestamp) {
        const date = new Date(unixTimestamp * 1000); // Convert to milliseconds
        return date.toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        });
      }
      

      function formatAppointmentMessage(appointmentData) {
        const { name, time } = appointmentData;
        let appointmentDate;
      
        // Check if the time string includes a year
        if (!/\b\d{4}\b/.test(time)) {
          // If no year, append the current year
          const currentYear = new Date().getFullYear();
          appointmentDate = new Date(`${time} ${currentYear}`);
        } else {
          // If time is in ISO format but incomplete, complete it
          if (/^\d{4}-\d{2}-\d{2}T\d{2}$/.test(time)) {
            appointmentDate = new Date(`${time}:00:00`);
          } else {
            appointmentDate = new Date(time);
          }
        }
      
        // Check if the date is valid
        if (isNaN(appointmentDate)) {
          // Return a standard message if the date is invalid
          return `Patient ${name} was booked for an appointment at an unknown time`;
        }
      
        // Format the date and time
        const formattedTime = appointmentDate.toLocaleString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
      
        return `Patient ${name} was booked for an appointment at ${formattedTime}`;
      }
      

      const columnDefinitions = [
        { key: 'description', header: 'Summary',  format: (value, activity) => formatAppointmentMessage(activity) },
        { key: 'name', header: 'Name' },
        { key: 'channel', header: 'Channel', default: 'Text' },
        { key: '_ts', header: 'Time', format: (value) => unixToFormattedDate(value) }
      ];

      return (
        <div className="grid min-h-screen w-full md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
            <div className="hidden border-r bg-muted/40 md:block">
                <Sidebar practiceId={practiceIdGlobal}/>
            </div>
    
            <div className="flex flex-col min-h-screen">
                <MainContent title="Home">
                <Tabs defaultValue="booked" onValueChange={setActiveTab}>
                <div className='flex justify-between items-center'>
                <TabsList>
                    <TabsTrigger value="booked">Activity Feed</TabsTrigger>
                    <TabsTrigger value="schedule">Schedule</TabsTrigger>
                    {practiceIdGlobal === "Acevedo1" && <TabsTrigger value="BugList">Bug List</TabsTrigger>}
                    {(practiceIdGlobal === "acevedo" || practiceIdGlobal === "wynn" || practiceIdAdmin === "Smiles Dental Admin") && <TabsTrigger value="analytics">Analytics</TabsTrigger>}
                </TabsList>

                <div className="flex space-x-3">
                        <Dialog>
                            <DialogTrigger className="inline-flex items-center space-x-2 text-sm text-muted-foreground leading-tight hover:text-foreground transition-colors mr-4">
                                <FilterIcon size={16} />
                                <span>Filter</span>
                            </DialogTrigger>
                            </Dialog>
                            <Dialog>
                            <DialogTrigger className="inline-flex items-center space-x-2 text-sm text-muted-foreground leading-tight hover:text-foreground transition-colors">
                                <ExportIcon size={16} />
                                <span>Export</span>
                            </DialogTrigger>
                        </Dialog>
                </div>
                </div>


                <TabsContent value="booked">
                    <Table>
                        <TableCaption>Activity Feed</TableCaption>
                        <TableHeader>
                        <TableRow>
                            {columnDefinitions.map(column => (
                            <TableHead key={column.key}>{column.header}</TableHead>
                            ))}
                        </TableRow>
                        </TableHeader>
                        <TableBody>
                        {activities.map(activity => (
                            <TableRow key={activity.id}>
                            {columnDefinitions.map(column => (
                              <TableCell key={column.key}>
                                {column.format 
                                  ? column.format(activity[column.key], activity) || column.default
                                  : activity[column.key] || column.default}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TabsContent>

                    <TabsContent value="schedule">
                        {/* Add your Schedule content here */}
                        <div>Schedule coming soon</div>
                    </TabsContent>
                    <TabsContent value="BugList">
                        {/* Add your Schedule content here */}
                        <BugList />
                    </TabsContent>

                    {(practiceIdGlobal === "acevedo" || practiceIdGlobal === "wynn" || practiceIdAdmin === "Smiles Dental Admin") && (
                        <TabsContent value="analytics">
                            <AnalyticsDashboard practiceId={practiceIdGlobal} />
                        </TabsContent>
                    )}
                </Tabs>
                </MainContent>
            </div>

            <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Edit Patient Preferences</DialogTitle>
                    </DialogHeader>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleEditSubmit();
                    }}>
                        <Input
                            placeholder="Patient Name"
                            value={editingPatient?.name || ''}
                            onChange={(e) => setEditingPatient({...editingPatient, name: e.target.value})}
                            className="mb-4"
                        />
                        <Input
                            placeholder="Preferences"
                            value={editingPatient?.preferenceString || ''}
                            onChange={(e) => setEditingPatient({...editingPatient, preferenceString: e.target.value})}
                            className="mb-4"
                        />
                        <DialogFooter>
                            <button type="submit" className="bg-primary text-white px-4 py-2 rounded-md">
                                Update
                            </button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Home;
