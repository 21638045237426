import React, { useState, useEffect } from 'react';

const getImage = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error('Error fetching image:', error);
    return null;
  }
};

const MessageDisplay = ({ message }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (message.isUrl) {
      const fetchImage = async () => {
        const url = await getImage(message.message);
        setImageUrl(url);
      };
      fetchImage();
    }
  }, [message]);

  const messageClass = `flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ${
    message.sender === 'system' ? 'ml-auto bg-muted' : 'bg-none'
  }`;

  if (message.isUrl) {
    return (
      <div className={messageClass}>
        {imageUrl ? (
          <img 
            src={imageUrl} 
            alt="Uploaded content" 
            className="max-w-full rounded-lg" 
            style={{ maxWidth: '300px', maxHeight: '300px', objectFit: 'contain' }}
          />
        ) : (
          <p>Loading image...</p>
        )}
      </div>
    );
  }

  return (
    <div className={messageClass}>
      {message.message.split('\n').map((line, index) => (
        <p key={index} className="break-words">{line}</p>
      ))}
    </div>
  );
};

const ConversationContainer = ({ messages }) => {
  return (
    <div className="flex flex-col h-[calc(100vh-200px)] overflow-y-auto p-4 space-y-4">
      {messages.map((message, index) => (
        <MessageDisplay key={index} message={message} />
      ))}
    </div>
  );
};

export default ConversationContainer;