import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Badge } from '../ui/Badge';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import { Send } from 'lucide-react';
import {
    Card,
    CardContent,
    CardFooter,
} from "../ui/Card"
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { useNavigate } from 'react-router-dom'; 
import { fetchCallDetails } from '../services/userInfoService'; // Import the helper function we created

const VoiceCallDetails = () => {
    const { callId } = useParams();
    const [callData, setCallData] = useState(null);
    const [practiceId, setPracticeId] = useState(localStorage.getItem('practiceId') || '');
    const navigate = useNavigate();

    console.log("VoiceCallDetails: callId ", callId)

    useEffect(() => {
        let practiceId = localStorage.getItem('practiceId');
        if (!practiceId) {
            console.log('No practiceId found in local storage');
            navigate('/');
            return;
        }

        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';

        const fetchData = async () => {
            try {
                const data = await fetchCallDetails(callId);
                console.log("voice details data ", data)
                setCallData(data);
            } catch (error) {
                console.error('Error fetching call data:', error);
            }
        }

        fetchData();

        return () => {
            document.documentElement.style.overflow = '';
            document.body.style.overflow = '';
        };
    }, [callId, navigate]);

    return (
        <div className="grid min-h-screen w-full md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
            <div className="hidden border-r bg-muted/40 md:block">
                <Sidebar practiceId={practiceId}/>
            </div>
            <div className="flex flex-col min-h-screen overflow-hidden">
                <MainContent title={`Call Transcript - ${callId}`} subtitle={callData?.start_timestamp ? new Date(callData.start_timestamp).toLocaleString() : ''}>
                    <div className="h-full w-full">
                        <Card className="flex h-3/4 flex-col mb-5">
                            <CardContent className='flex-grow overflow-auto pt-5 max-h-[calc(100vh-300px)]'>
                                {callData && callData.transcript_object ? (
                                    callData.transcript_object.map((message, index) => (
                                        <div
                                            key={index}
                                            className={`flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ${
                                                message.role === 'agent'
                                                    ? 'ml-auto bg-muted'
                                                    : 'bg-none'
                                            }`}
                                        >
                                            <strong>{message.role === 'agent' ? 'Agent' : 'User'}:</strong> {message.content}
                                        </div>
                                    ))
                                ) : (
                                    <div>Loading transcript...</div>
                                )}
                            </CardContent>
                            <CardFooter className="h-1/4 align-center">
                                <div className="w-full text-center">
                                    {callData?.call_status === 'ended' && (
                                        <Badge variant="outline">Call Ended</Badge>
                                    )}
                                </div>
                            </CardFooter>
                        </Card>
                    </div>
                </MainContent>
            </div>
        </div>
    );
};

export default VoiceCallDetails;