import React, { useEffect, useState, useCallback } from 'react';
import {
  ReactFlow,
  Controls,
  Background,
  MiniMap,
  addEdge
} from '@xyflow/react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../ui/Dialog";
import Message from './Message';
import { useNodesState, useEdgesState } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { getData, updateData, flowToNodesEdges, nodesEdgesToFlow, deleteFlow } from '../services/engagementService';

const nodeTypes = {
  message: Message,
};

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, label, data, style = {}, markerEnd }) => {
  const [edgeLabel, setEdgeLabel] = useState(label);

  const handleLabelChange = useCallback((event) => {
    const value = event.target.value.replace(/\D/g, '');
    setEdgeLabel(value);
  }, []);

  const handleBlur = useCallback(() => {
    if (data && typeof data.onChange === 'function') {
      data.onChange(id, edgeLabel);
    }
  }, [id, edgeLabel, data]);

  const edgePath = `M ${sourceX},${sourceY} L ${targetX},${targetY}`;

  return (
    <>
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
      <foreignObject
        width={100}
        height={30}
        x={(sourceX + targetX) / 2 - 50}
        y={(sourceY + targetY) / 2 - 15}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div className="flex items-center justify-center bg-white border rounded px-2 w-full h-full">
          <input
            value={edgeLabel}
            onChange={handleLabelChange}
            onBlur={handleBlur}
            className="nodrag nopan w-10 text-center mr-1"
            type="text"
            pattern="\d*"
          />
          <span className="text-xs whitespace-nowrap">days</span>
        </div>
      </foreignObject>
    </>
  );
};

const edgeTypes = {
  custom: CustomEdge,
};

const Engagement = ({ practiceId }) => {
  const [flows, setFlows] = useState([]);
  const [currentFlowId, setCurrentFlowId] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [newFlowType, setNewFlowType] = useState('Schedule');
  const [newFlowTrigger, setNewFlowTrigger] = useState(0);
  const [isCreatingNewFlow, setIsCreatingNewFlow] = useState(false);
  const [newFlowName, setNewFlowName] = useState('');
  const [patientType, setPatientType] = useState('Existing');
  const [isEditingFlow, setIsEditingFlow] = useState(false);
  const [editFlowName, setEditFlowName] = useState('');
  const [editFlowType, setEditFlowType] = useState('Schedule');
  const [editFlowTrigger, setEditFlowTrigger] = useState(0);
  const [editPatientType, setEditPatientType] = useState('Existing');
  const [errorMessage, setErrorMessage] = useState('');

  const currentFlow = flows.find(flow => flow.id === currentFlowId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadedFlows = await getData(practiceId);
        setFlows(loadedFlows);
        if (loadedFlows.length > 0) {
          setCurrentFlowId(loadedFlows[0].id);
        }
      } catch (error) {
        console.error('Error loading flows:', error);
      }
    };
    fetchData();
  }, [practiceId]);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({ ...params, label: '1', type: 'custom' }, eds)),
    [setEdges]
  );

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    setEdges((els) => els.map((el) => {
      if (el.id === oldEdge.id) {
        return {
          ...el,
          source: newConnection.source || el.source,
          target: newConnection.target || el.target,
          sourceHandle: newConnection.sourceHandle || el.sourceHandle,
          targetHandle: newConnection.targetHandle || el.targetHandle,
        };
      }
      return el;
    }));
  }, [setEdges]);

  const handleFlowChange = (flowId) => {
    setCurrentFlowId(flowId);
  };

  const handleCreateNewFlow = async () => {
    const newFlow = {
      name: newFlowName || `New Flow ${flows.length + 1}`,
      type: newFlowType,
      trigger: newFlowTrigger,
      patientType: newFlowType === 'Confirm' ? patientType : undefined,
      sequence: [
        {
          id: 'node1',
          action: 'text',
          copy: 'Initial node content',
          next: null,
        },
      ],
    };
    try {
      const createdFlow = await updateData(newFlow, practiceId);
      setFlows((prevFlows) => [...prevFlows, createdFlow]);
      setCurrentFlowId(createdFlow.id);
  
      const { nodes: newNodes, edges: newEdges } = flowToNodesEdges(createdFlow);
      setNodes(
        newNodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            onChange: handleUpdateNodeData,
            onDelete: handleDeleteNode,
            flowType: createdFlow.type,
          },
        }))
      );
      setEdges(newEdges.map((edge) => ({ ...edge, type: 'custom' })));
  
      // Reset the form and close the dialog
      setNewFlowName('');
      setNewFlowType('Schedule');
      setNewFlowTrigger(0);
      setIsCreatingNewFlow(false);
    } catch (error) {
      console.error('Error creating new flow:', error);
    }
  };

  const handleEditFlowSave = async () => {
    const updatedFlow = {
      ...currentFlow,
      name: editFlowName,
      type: editFlowType,
      trigger: editFlowTrigger,
      patientType: editFlowType === 'Confirm' ? editPatientType : undefined,
    };
  
    try {
      const savedFlow = await updateData(updatedFlow);
      const updatedFlows = flows.map(flow =>
        flow.id === currentFlowId ? savedFlow : flow
      );
      setFlows(updatedFlows);
      setCurrentFlowId(savedFlow.id);
  
      // Update nodes and edges if necessary
      const { nodes: newNodes, edges: newEdges } = flowToNodesEdges(savedFlow);
      setNodes(
        newNodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            onChange: handleUpdateNodeData,
            onDelete: handleDeleteNode,
            flowType: savedFlow.type,
          },
        }))
      );
      setEdges(newEdges.map((edge) => ({ ...edge, type: 'custom' })));
  
      // Close the modal
      setIsEditingFlow(false);
    } catch (error) {
      console.error('Error updating flow:', error);
    }
  };

  const handleDeleteFlow = async () => {
    if (window.confirm('Are you sure you want to delete this flow?')) {
      try {
        await deleteFlow(currentFlowId, practiceId);
        const updatedFlows = flows.filter((flow) => flow.id !== currentFlowId);
        setFlows(updatedFlows);
  
        // Clear nodes and edges
        setNodes([]);
        setEdges([]);
  
        // Select another flow if available
        if (updatedFlows.length > 0) {
          handleFlowChange(updatedFlows[0].id);
        } else {
          setCurrentFlowId(null);
        }
      } catch (error) {
        console.error('Error deleting flow:', error);
        setErrorMessage('Failed to delete flow.');
      }
    }
  };
  
  
  const handleAddNode = () => {
    const newNode = {
      id: `node${nodes.length + 1}`,
      type: 'message',
      position: { x: nodes.length * 250, y: 100 },
      data: {
        title: `Step ${nodes.length + 1}`,
        status: 'text',
        copy: 'New node content',
        flowType: currentFlow.type,
        onChange: handleUpdateNodeData,
        onDelete: handleDeleteNode,
      },
    };
    setNodes((nds) => [...nds, newNode]);
  };

  const handleAddEdge = () => {
    if (nodes.length < 2) return;
    const newEdge = {
      id: `e${edges.length + 1}`,
      source: nodes[nodes.length - 2].id,
      target: nodes[nodes.length - 1].id,
      label: '1',
      type: 'custom',
      data: { onChange: handleEdgeUpdate }
    };
    setEdges((eds) => [...eds, newEdge]);
  };

  const handleEdgeUpdate = useCallback((edgeId, newLabel) => {
    setEdges(prevEdges =>
      prevEdges.map(edge =>
        edge.id === edgeId 
          ? { ...edge, label: newLabel, data: { ...edge.data, onChange: handleEdgeUpdate } } 
          : edge
      )
    );
  }, [setEdges]);

  const handleDeleteNode = useCallback(
    (nodeId) => {
      if (window.confirm('Are you sure you want to delete this node?')) {
        setNodes((nds) => nds.filter((node) => node.id !== nodeId));
        setEdges((eds) =>
          eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId)
        );
      }
    },
    [setNodes, setEdges]
  );

  const handleUpdateNodeData = useCallback((nodeId, newCopy) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
              ...node,
              data: {
                ...node.data,
                copy: newCopy, // Update only the copy
                onChange: handleUpdateNodeData,
                onDelete: handleDeleteNode,
              },
            }
          : node
      )
    );
  }, [setNodes, handleDeleteNode]);

  const validateFlow = () => {
    if (nodes.length > 1) {
      const nodeIds = nodes.map(node => node.id);
      const nodesWithIncomingEdges = new Set(edges.map(edge => edge.target));
      const firstNodeId = nodes[0].id;
  
      const nodesWithoutIncomingEdges = nodeIds.filter(nodeId =>
        nodeId !== firstNodeId && !nodesWithIncomingEdges.has(nodeId)
      );
  
      if (nodesWithoutIncomingEdges.length > 0) {
        const nodeNames = nodesWithoutIncomingEdges.join(', ');
        setErrorMessage(`Cannot save flow. The following nodes do not have incoming edges: ${nodeNames}`);
        return false;
      }
    }
    return true;
  };

  const handleSaveFlow = async () => {
    setErrorMessage(''); // Clear previous errors
    if (!validateFlow()) {
      return; // Stop if validation fails
    }
  
    const currentFlow = flows.find(flow => flow.id === currentFlowId);
    if (currentFlow) {
      const updatedFlow = nodesEdgesToFlow(
        currentFlow.id,
        currentFlow.name,
        currentFlow.type,
        currentFlow.trigger,
        currentFlow.patientType,
        nodes,
        edges
      );
      try {
        const savedFlow = await updateData(updatedFlow, practiceId);
        const updatedFlows = flows.map(flow =>
          flow.id === currentFlowId ? savedFlow : flow
        );
        setFlows(updatedFlows);
      } catch (error) {
        console.error('Error saving flow:', error);
      }
    }
  };
  
  useEffect(() => {
    if (currentFlowId) {
      const currentFlow = flows.find((flow) => flow.id === currentFlowId);
      if (currentFlow) {
        const { nodes: newNodes, edges: newEdges } = flowToNodesEdges(currentFlow);
        setNodes(
          newNodes.map((node) => ({
            ...node,
            data: {
              ...node.data,
              onChange: handleUpdateNodeData,
              onDelete: handleDeleteNode,
              flowType: currentFlow.type,
            },
          }))
        );
        setEdges(newEdges.map((edge) => ({ ...edge, type: 'custom' })));
      }
    }
  }, [currentFlowId, flows, handleUpdateNodeData, handleDeleteNode, setNodes, setEdges]);

  return (
    <div className="w-full h-[80vh] p-0 flex flex-col overflow-hidden">
        <div className="flex-shrink-0 p-6 pb-2">
          <h1 className="text-2xl font-semibold">
            Engagement Flows
          </h1>
        </div>
        <div className="flex space-x-4 p-4">
          {flows.map((flow) => (
            <button
              key={flow.id}
              onClick={() => handleFlowChange(flow.id)}
              className={`px-4 py-2 ${
                currentFlowId === flow.id ? 'bg-blue-500 text-white' : 'bg-gray-200'
              } rounded`}
            >
              {flow.name}
            </button>
          ))}
          <button
            onClick={() => setIsCreatingNewFlow(true)}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            + New Flow
          </button>
          <button
            onClick={() => {
              setEditFlowName(currentFlow.name);
              setEditFlowType(currentFlow.type);
              setEditFlowTrigger(currentFlow.trigger);
              setEditPatientType(currentFlow.patientType || 'Existing');
              setIsEditingFlow(true);
            }}
            className="px-4 py-2 bg-yellow-500 text-white rounded"
          >
            Edit Flow
          </button>
          <button
            onClick={handleAddNode}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            + Add Node
          </button>
          <button
            onClick={handleAddEdge}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            + Add Edge
          </button>
          <button
            onClick={handleSaveFlow}
            className="px-4 py-2 bg-purple-500 text-white rounded"
          >
            Save Flow
          </button>
          <button
          onClick={handleDeleteFlow}
          className="px-4 py-2 bg-red-500 text-white rounded"
        >
          Delete Flow
        </button>
        </div>
        {errorMessage && (
          <div className="px-4 py-2 bg-red-100 text-red-700">
            {errorMessage}
          </div>
        )}
        {currentFlow && (
          <div className="px-4 py-2 bg-gray-100">
            <span className="font-bold">{currentFlow.type}</span>: 
            <span>
              {currentFlow.trigger} days {currentFlow.type === 'Confirm' ? 'before appointment' : 'after last appointment'}
            </span>
            {currentFlow.type === 'Confirm' && currentFlow.patientType && (
              <span> - {currentFlow.patientType} Patients</span>
            )}
          </div>
        )}
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onEdgeUpdate={onEdgeUpdate}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          fitView
          fitViewOptions={{ padding: 0.2 }}
          attributionPosition="bottom-left"
          deleteKeyCode={[]}
          defaultEdgeOptions={{ type: 'custom' }}
        >
          <Background />
          <Controls />
          <MiniMap />
        </ReactFlow>
        {isCreatingNewFlow && (
          <Dialog open={isCreatingNewFlow} onOpenChange={setIsCreatingNewFlow}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Create New Flow</DialogTitle>
              </DialogHeader>
              <div className="p-4">
                <div className="mb-4">
                  <label className="block mb-1">Flow Name</label>
                  <input
                    type="text"
                    value={newFlowName}
                    onChange={(e) => setNewFlowName(e.target.value)}
                    className="p-2 border rounded w-full"
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1">Flow Type</label>
                  <select
                    value={newFlowType}
                    onChange={(e) => setNewFlowType(e.target.value)}
                    className="p-2 border rounded w-full"
                  >
                    <option value="Schedule">Schedule</option>
                    <option value="Confirm">Confirm</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block mb-1">
                    Trigger Days {newFlowType === 'Confirm' ? 'before appointment' : 'after last appointment'}
                  </label>
                  <input
                    type="number"
                    value={newFlowTrigger}
                    onChange={(e) => setNewFlowTrigger(parseInt(e.target.value) || 0)}
                    className="p-2 border rounded w-full"
                  />
                </div>
                {newFlowType === 'Confirm' && (
                  <div className="mb-4">
                    <label className="block mb-1">Patient Type</label>
                    <select
                      value={patientType}
                      onChange={(e) => setPatientType(e.target.value)}
                      className="p-2 border rounded w-full"
                    >
                      <option value="New">New</option>
                      <option value="Existing">Existing</option>
                    </select>
                  </div>
                )}
                <button
                  onClick={handleCreateNewFlow}
                  className="px-4 py-2 bg-green-500 text-white rounded"
                >
                  Create Flow
                </button>
              </div>
            </DialogContent>
          </Dialog>
        )}
        {isEditingFlow && (
          <Dialog open={isEditingFlow} onOpenChange={setIsEditingFlow}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Edit Flow</DialogTitle>
              </DialogHeader>
              <div className="p-4">
                <div className="mb-4">
                  <label className="block mb-1">Flow Name</label>
                  <input
                    type="text"
                    value={editFlowName}
                    onChange={(e) => setEditFlowName(e.target.value)}
                    className="p-2 border rounded w-full"
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1">Flow Type</label>
                  <select
                    value={editFlowType}
                    onChange={(e) => setEditFlowType(e.target.value)}
                    className="p-2 border rounded w-full"
                  >
                    <option value="Schedule">Schedule</option>
                    <option value="Confirm">Confirm</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block mb-1">
                    Trigger Days {editFlowType === 'Confirm' ? 'before appointment' : 'after last appointment'}
                  </label>
                  <input
                    type="number"
                    value={editFlowTrigger}
                    onChange={(e) => setEditFlowTrigger(parseInt(e.target.value) || 0)}
                    className="p-2 border rounded w-full"
                  />
                </div>
                {editFlowType === 'Confirm' && (
                  <div className="mb-4">
                    <label className="block mb-1">Patient Type</label>
                    <select
                      value={editPatientType}
                      onChange={(e) => setEditPatientType(e.target.value)}
                      className="p-2 border rounded w-full"
                    >
                      <option value="New">New</option>
                      <option value="Existing">Existing</option>
                    </select>
                  </div>
                )}
                <button
                  onClick={handleEditFlowSave}
                  className="px-4 py-2 bg-green-500 text-white rounded"
                >
                  Save Changes
                </button>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
  );
};

export default Engagement;