import React, { useState, useEffect, useCallback } from 'react';
import { Badge } from '../ui/Badge';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table";
import { getConversationsByPractice } from '../services/messagesService';
import { fetchCallData, fetchAllCallData } from '../services/userInfoService'
import { useNavigate } from 'react-router-dom';
import { Button } from "../ui/Button";
import { Pagination, PaginationContent, PaginationItem, PaginationPrevious, PaginationNext, PaginationLink, PaginationEllipsis } from '../ui/Pagination';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../ui/Tabs';

function unixToFormattedDate(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Convert to milliseconds
  return date.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
  });
}

const Conversations = () => {
  const [conversations, setConversations] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [globalPracticeId, setGlobalPracticeId] = useState('');
  const [currentPage, setCurrentPage] = useState(() => {
    return parseInt(sessionStorage.getItem('conversationsPage')) || 1;
  });
  const [searchTerm, setSearchTerm] = useState('');
  const pageSize = 10;
  const [activeTab, setActiveTab] = useState(() => {
    return sessionStorage.getItem('conversationsTab') || 'phone';
  });

 console.log("currentPage ", currentPage);
 console.log("activeTab ", activeTab);

  const fetchConversations = useCallback((page, search, tabType) => {
    const practiceId = localStorage.getItem('practiceId') || '';
    if (!practiceId) {
      console.log('No practiceId found in local storage');
      navigate('/');
      return;
    }

    setGlobalPracticeId(practiceId);

    let practiceIdForActivities = practiceId;
    if (practiceId === "Admin") {
      practiceIdForActivities = ['Jemin', 'Chua', 'wynn', "starBrite", "Jemin2", "GraceDental", "IAS", "nkc1", "starBriteFremont", "Acevedo1"];
    }
    if (practiceId === "pAdmin") {
      practiceIdForActivities = ["p1", "p2", "p3"];
    }
    console.log("practiceIdForActivities ", practiceIdForActivities)
    if (tabType === "text")
    {
      getConversationsByPractice(practiceIdForActivities, page, pageSize, search)
      .then(data => {
        const modifiedConversations = data.map(convo => ({
          id: convo.id || '',
          timeStarted: convo.timeStarted || 'n/a',
          summary: convo.summary || 'n/a',
          type: convo.type || 'Text',
          patientName: convo.name || '',
          nextAppointment: convo.nextAppointment || 'n/a',
          phoneNumber: convo.phoneNumber,
          location: convo.location,
          time : unixToFormattedDate(convo._ts)
        }));

        setConversations(modifiedConversations);
      })
      .catch(error => setError(error));
    }
    else
    {
      fetchAllCallData(practiceIdForActivities, page, pageSize, search)
      .then(data => {
        const modifiedConversations = data.calls.map(convo => ({
          id: convo.id || '',
          timeStarted: convo.time || 'n/a',
          summary: convo.summary || 'n/a',
          type: 'Call',
          patientName: convo.name || '',
          nextAppointment: 'n/a',
          phoneNumber: convo.phoneNumber,
          location: convo.location
        })).sort((a, b) => new Date(b.timeStarted) - new Date(a.timeStarted));;
        // remove test convos 
        const phoneNumbersToRemove = ['+16178349372', '+14153076783', '+17816907444'];
        const filteredConversations = modifiedConversations.filter(convo => !phoneNumbersToRemove.includes(convo.phoneNumber));
        setConversations(filteredConversations);
      })
      .catch(error => setError(error));
    }
    
  }, [navigate]);

  const handleSearch = useCallback((value) => {
    setSearchTerm(value);
    setCurrentPage(1);
    fetchConversations(1, value, activeTab);
  }, [fetchConversations, activeTab]);

  useEffect(() => {
    fetchConversations(currentPage, searchTerm, activeTab);
  }, [currentPage, searchTerm, activeTab, fetchConversations]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(searchTerm !== "")
      {
        setCurrentPage(1);
        fetchConversations(1, searchTerm,activeTab);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, fetchConversations, activeTab]);

  useEffect(() => {
    sessionStorage.setItem('conversationsPage', currentPage.toString());
  }, [currentPage]);
  
  useEffect(() => {
    sessionStorage.setItem('conversationsTab', activeTab);
  }, [activeTab]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleRowClick = (convo) => {
    navigate(`/conversation_/${convo.phoneNumber}`, {
      state: {
        patientName: convo.patientName,
      }
    });
  };

  const handleRowClickVoice = (convo) => {
    navigate(`/voiceCall/${convo.id}`, {
      state: {
        patientName: convo.patientName,
      }
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    sessionStorage.setItem('conversationsPage', page.toString());
    console.log("sessionStorage.getItem('conversationsPage') ", sessionStorage.getItem('conversationsPage'))
    fetchConversations(page, searchTerm, activeTab);
  };

  const handleTabChange = (value) => {
    setActiveTab(value);
    setCurrentPage(1);
    sessionStorage.setItem('conversationsTab', value);
    sessionStorage.setItem('conversationsPage', '1');
    console.log("sessionStorage.getItem('conversationsPage') ", sessionStorage.getItem('conversationsPage'))
    fetchConversations(1, searchTerm, value);
  };

  return (
    <div className="grid h-screen w-screen md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
      <div className="hidden border-r bg-muted/40 md:block">
        <Sidebar practiceId={globalPracticeId}/>
      </div>
      <div className="flex flex-col h-screen">
        <MainContent title="Conversations" onSearch={handleSearch}>
          <Tabs value={activeTab}  onValueChange={handleTabChange}>
            <TabsList>
              <TabsTrigger value="phone">Phone</TabsTrigger>
              <TabsTrigger value="text">Text</TabsTrigger>
            </TabsList>
            <TabsContent value="phone">
              <div className="flex flex-col h-full">
                <div className="flex-1 overflow-auto">
                  <h2 className="text-lg font-semibold mb-2">Phone Conversations</h2>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Time</TableHead>
                        <TableHead>Name</TableHead>
                        <TableHead> Number</TableHead>
                        <TableHead>Summary</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {conversations.map((convo) => (
                        <TableRow
                          key={convo.id}
                          onClick={() => handleRowClickVoice(convo)}
                          className="cursor-pointer"
                        >
                          <TableCell>{convo.timeStarted}</TableCell>
                          <TableCell>{convo.patientName}</TableCell>
                          <TableCell>{convo.phoneNumber}</TableCell>
                          <TableCell className={`max-w-md ${convo.resolved ? 'line-through' : ''}`}>
                            {convo.summary}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <div className="mt-4 flex justify-center">
                  <Pagination>
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious 
                          onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                          disabled={currentPage === 1}
                        />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink 
                          isActive={true}
                          onClick={() => handlePageChange(currentPage)}
                        >
                          {currentPage}
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationEllipsis />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationNext 
                          onClick={() => handlePageChange(currentPage + 1)}
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="text">
              <div className="flex flex-col h-full">
                <div className="flex-1 overflow-auto">
                 <h2 className="text-lg font-semibold mb-2">Text Conversations</h2>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Time</TableHead>
                        <TableHead>Name</TableHead>
                        <TableHead>Number</TableHead>
                        <TableHead>Summary</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {conversations.map((convo) => (
                        <TableRow
                          key={convo.id}
                          onClick={() => handleRowClick(convo)}
                          className="cursor-pointer"
                        >
                          <TableCell>{convo.time}</TableCell>
                          <TableCell>{convo.patientName}</TableCell>
                          <TableCell>{convo.phoneNumber}</TableCell>
                          <TableCell className="max-w-md truncate">{convo.summary}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <div className="mt-4 flex justify-center">
                  <Pagination>
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious 
                          onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                          disabled={currentPage === 1}
                        />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink 
                          isActive={true}
                          onClick={() => handlePageChange(currentPage)}
                        >
                          {currentPage}
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationEllipsis />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationNext 
                          onClick={() => handlePageChange(currentPage + 1)}
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </MainContent>
      </div>
    </div>
  );
};

export default Conversations;